import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_41_665)">
      <circle
        cx={14.9218}
        cy={16.6183}
        r={8.3606}
        transform="rotate(45 14.9218 16.6183)"
        fill="#5158F8"
      />
      <path
        d="M18.2319 13.4761C20.0716 15.3157 22.3086 16.0614 23.2284 15.1416C24.1437 14.2263 23.4099 12.0068 21.5902 10.1724C23.9355 12.5325 24.8824 15.3911 23.7043 16.5691C22.5216 17.7518 19.6455 16.7931 17.2802 14.4278C14.9149 12.0625 13.9562 9.18633 15.1388 8.00369C16.3169 6.82557 19.1756 7.77246 21.5357 10.118C19.7013 8.29809 17.4817 7.56425 16.5664 8.47955C15.6466 9.39939 16.3923 11.6364 18.2319 13.4761Z"
        fill="#4047E5"
      />
      <path
        d="M16.0835 16.1956C19.5526 19.6646 23.6319 21.2098 25.1949 19.6469C26.0741 18.7677 25.9698 17.0924 25.093 15.1774C26.8522 18.1823 27.2925 20.9459 25.9623 22.2761C23.9749 24.2635 18.7878 22.2987 14.3767 17.8876C9.96559 13.4764 8.0008 8.28937 9.98822 6.30194C11.3277 4.96249 14.1205 5.41824 17.1496 7.20819C15.2146 6.31106 13.5187 6.19771 12.6323 7.08418C11.0693 8.64717 12.6145 12.7265 16.0835 16.1956Z"
        fill="#4047E5"
      />
      <path
        d="M12.93 19.349C18.2913 24.7103 24.555 27.1391 26.9203 24.7738C28.05 23.644 28.0861 21.6249 27.2312 19.2396C28.5618 22.5072 28.5855 25.3928 26.9891 26.9891C24.0719 29.9063 16.8496 27.4136 10.8575 21.4216C4.86544 15.4295 2.37277 8.20714 5.28995 5.28995C6.88621 3.6937 9.77151 3.71725 13.0389 5.0476C10.6538 4.19297 8.6349 4.22909 7.50523 5.35876C5.13994 7.72405 7.5687 13.9877 12.93 19.349Z"
        fill="#4047E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_41_665">
        <rect width={32} height={33} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
